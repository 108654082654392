import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { AddSystemDialog, Button, DropdownMenu, HeaderDropdown, LogoOverlay, Tooltip } from 'components';
import { bottomNav, Routes as NavigationRoutes, topNav, UserRole } from 'models';
import { setSelectedFacility, setSelectedSystem, standardApi, useAppDispatch, useAppSelector, useSystem } from 'store';
import { getFacilityName } from 'utils';
import Logo from 'assets/images/branding/logo.svg';
import NavMenu from 'components/Layout/NavigationMenu';
import { useAuth } from 'context';
import { useGetNotificationInboxQuery } from 'store/services/NotificationService';

import type { Facility, Healthsystem } from 'models';

function Header() {
	const [sideNavOpen, setSideNavOpen] = useState(false);
	const [hoverNavOpen, setHoverNavOpen] = useState(false);
	const dispatch = useAppDispatch();
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);
	const { data: authData, logout } = useAuth();
	const { isLoading, data } = useSystem();
	const isAdmin = data?.user.role === UserRole.admin;
	const isHealthSystemAdmin = data?.user.role === UserRole.healthsystem_admin;
	const user_facilities = useMemo(() => {
		return data?.user?.facilities.map((e) => e.id) ?? [];
	}, [data?.user?.facilities]);
	const system = data?.healthsystems.find((h) => h.id === selectedSystem);
	const facilities = data?.facilities;
	const facility = facilities?.find((f) => f.id === selectedFacility);
	const location = useLocation();

	// "Logout" click handler
	const handleClickLogout = useCallback(() => {
		logout();
	}, [logout]);

	// Local utility for toggling the nav open/closed state
	const toggleSidebar = () => setSideNavOpen(!sideNavOpen);

	// Used to hide header for printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? null;
	const { data: notifications } = useGetNotificationInboxQuery(undefined);
	const unread_notifications = notifications?.notifications?.filter((n) => !n.read_status).length ?? 0;

	// determine default health system and facility based on url
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);

	useEffect(() => {
		const preselected_hs = params.get('preselected_healthsystem_id');
		if (preselected_hs !== null) {
			dispatch(setSelectedSystem(parseInt(preselected_hs)));
			dispatch(standardApi.util.resetApiState());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.get('preselected_healthsystem_id')]);

	const SettingsDropdownMenu = [
		{
			label: 'Account',
			url: NavigationRoutes.ACCOUNT,
		},
		{
			label: 'Settings',
			url: NavigationRoutes.SETTINGS,
		},
		{
			label: 'Aliases',
			url: NavigationRoutes.ALIASES,
		},
		{
			label: 'Admin',
			url: NavigationRoutes.ADMIN,
		},
		{
			label: 'Analytics',
			url: NavigationRoutes.ANALYTICS,
		},
		{
			label: 'Notifications',
			url: NavigationRoutes.NOTIFICATIONS,
			focusDot: unread_notifications > 0,
		},
	];

	// if no facility selected, default to first one
	useEffect(() => {
		if (!getFacilityName(facility) && data?.facilities !== undefined) {
			dispatch(setSelectedFacility(data.facilities[0].id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getFacilityName(facility)]);

	return (
		<>
			{printable && (
				<div className='block h-screen w-screen z-50 bg-white'>
					<LogoOverlay backgroundColor='white' printing={printable} />
				</div>
			)}
			<div className={`${printable ? 'hidden' : 'flex flex-col'} sticky top-0 z-topMenu`}>
				<div className='flex flex-shrink-0 h-16 bg-white shadow'>
					<div className='flex flex-1 justify-between pl-36 pr-4'>
						<div className='flex items-center gap-2'>
							<div className='flex'>
								{isAdmin && (
									<>
										<HeaderDropdown
											activeText={system?.name ?? (isLoading ? '(Loading...)' : '(None)')}
											filterFn={(item: Healthsystem, searchQuery: string) => {
												return item.name.toLowerCase().includes(searchQuery.toLowerCase());
											}}
											label={
												<>
													<span className='hidden xl:inline'>Health System</span>
													<span className='xl:hidden'>System</span>
												</>
											}
											onSelect={(option: Healthsystem) => {
												// when selecting a new healthsystem, always completely reset the cache
												if (option.id !== selectedSystem) {
													dispatch(setSelectedSystem(option.id));
													dispatch(standardApi.util.resetApiState());
												}
											}}
											options={data.healthsystems}
											renderTextFn={(item: Healthsystem) => item.name}
										/>

										<AddSystemDialog>
											<Button variant='primary-ghost' sizeX='square' circle>
												<Tooltip content={'Create Health System'} triggerType='span'>
													<span className='material-symbol-sm'>domain_add</span>
												</Tooltip>
											</Button>
										</AddSystemDialog>
									</>
								)}
							</div>
							{facilities && (
								<HeaderDropdown
									activeText={isLoading ? '(Loading...)' : (facility && getFacilityName(facility)) ?? '(None)'}
									filterFn={(item: Facility, searchQuery: string) => {
										return getFacilityName(item).toLowerCase().includes(searchQuery.toLowerCase());
									}}
									label={
										<>
											<span className='hidden xl:inline'>Your facility is set to</span>
											<span className='xl:hidden'>Facility</span>
										</>
									}
									onSelect={(option: Facility) => {
										dispatch(setSelectedFacility(option.id));
									}}
									options={facilities.filter((e) => user_facilities?.includes(e.id) || isAdmin || isHealthSystemAdmin)}
									renderTextFn={(item: Facility) => getFacilityName(item)}
								/>
							)}
						</div>
						<div className='flex items-center gap-2'>
							{/* <TextField
								label='Search'
								hideLabel
								icon='search'
								placeholder='Search by name, date etc.'
								iconClass='text-blue-600'
								className='w-[225px] h-[40px] bg-blue-100 border-none placeholder-gray-500'
								sizeY='md'
							/> */}
							<div className='flex relative'>
								<DropdownMenu
									options={SettingsDropdownMenu.filter(
										(item) => (item.label !== 'Notifications' && item.label !== 'Admin' && item.label !== 'Analytics') || isAdmin
									)}
								>
									<Button
										sizeX='square'
										sizeY='icon'
										circle
										variant='secondary-ghost'
										className='bg-blue-200 border-blue-200 items-end'
									>
										{unread_notifications > 0 && isAdmin && (
											<span className='animate bg-red-600 text-white text-[0.7em] w-fit px-1.5 pb-0.5 h-fit rounded-lg absolute top-[-0.5em] right-[-0.5em]'>
												{unread_notifications > 99 ? '99+' : unread_notifications}
											</span>
										)}

										{`${authData.user.given_name?.[0] ?? ''}${authData.user.family_name?.[0] ?? ''}`}
									</Button>
								</DropdownMenu>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				onMouseEnter={() => setHoverNavOpen(true)}
				onMouseLeave={() => setHoverNavOpen(false)}
				className={classNames(
					hoverNavOpen || sideNavOpen ? 'w-64' : 'w-24',
					`shadow-lg ease-in-out bg-white transition-all fixed inset-y-0 border-r border-grey-300 z-sideMenu ${
						printable && 'hidden'
					}`
				)}
			>
				<button
					aria-label='Open Navigation'
					className='absolute top-7 -right-3 rounded-full text-blue-700 bg-white border border-gray-300 h-6 w-6 text-center'
					onClick={toggleSidebar}
				>
					<div className={classNames('material-symbol transition-transform -translate-y-px', sideNavOpen && '-scale-x-100')}>
						chevron_right
					</div>
				</button>

				<div className='flex flex-col h-full'>
					<div className='pt-5 pl-8'>
						<img className='h-8 w-8 relative -left-[2px]' src={Logo} alt='Merlin' />
					</div>

					<div
						className={classNames('mt-5 flex flex-col basis-full overflow-x-hidden', {
							'overflow-y-auto slate-customscrollbar': hoverNavOpen || sideNavOpen,
							'overflow-y-hidden': !hoverNavOpen && !sideNavOpen,
						})}
					>
						<NavMenu
							facility_license={facility?.license}
							menu={topNav}
							location={location}
							hovering={hoverNavOpen || sideNavOpen}
							has_schedule={facility?.has_scheduled}
						/>
						<hr className='border-t-2 border-gray-200 mx-6 my-2' />
						<NavMenu
							facility_license={facility?.license ?? ''}
							menu={bottomNav}
							location={location}
							hovering={hoverNavOpen || sideNavOpen}
						/>
						<button
							onClick={handleClickLogout}
							className={classNames(
								'flex items-center gap-3 pl-8 py-6 w-full mt-auto',
								'whitespace-nowrap text-left bg-blue-50 text-blue-700'
							)}
						>
							<span className='material-symbol ml-1'>logout</span>
							<span className={classNames(hoverNavOpen || sideNavOpen ? 'opacity-1' : 'opacity-0', 'transition-opacity')}>
								Logout
							</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;
