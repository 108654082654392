import { format } from 'date-fns';
import React, { ReactHTMLElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Selection,
	VictoryArea,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';
import classNames from 'classnames';
import { ColumnDef, Row } from '@tanstack/react-table';
import { debounce } from 'lodash';

import {
	Button,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	Heatmap,
	LogoOverlay,
	NumberField,
	PageHeading,
	Panel,
	Tooltip,
} from 'components';
import {
	AnesthesiaCoverageResponse,
	dropDowns,
	Shift,
	useAppSelector,
	useGetAnesthesiaCoverageQuery,
	useSystem,
} from 'store';
import { FilterContextField, useFilters, useToast } from 'context';
import { DAYS_OF_WEEK, DAYS_OF_WEEK_LIST, fontFamily, numberWithCommas } from 'utils';
import { ToggleButton } from 'pages/Block/components/ReleaseDialog';
import { ColumnMap } from 'pages/Core';

import { StaffingGrid, StaffingGridItem } from '../components';

const darkGradient = '#4A55A2';
const lightGradient = '#7895CB';
const idealStroke = '#A0BFE0';
const idealFill = 'rgba(160, 191, 224, 0.3)';
const customStroke = '#C4B0FF';
const customFill = 'rgba(196, 176, 255, 0.3)';

interface ToggleStateType {
	medical_ratio: number;
	eight_hour_shift: boolean;
	ten_hour_shift: boolean;
	twelve_hour_shift: boolean;
	fourteen_hour_shift: boolean;
	carousel_day_of_week_selected: string | undefined;
}

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: {
		x: number;
		y: number;
		mda_heatmap_y?: number;
		mda_ideal_y?: number;
		mda_custom_y?: number;
		crna_heatmap_y?: number;
		crna_ideal_y?: number;
		crna_custom_y?: number;
	};
	id: string;
}> {
	render() {
		const { x2, y2, datum, id } = this.props;
		return (
			<foreignObject x={200} y={100} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-32' id={id}>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Hour of Day</p>
						<p className='text-right text-[0.5em] text-white'>{datum?.x}</p>
					</div>
					{id === 'MDA_heatmap' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Concurrent Anesthesiologists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.mda_heatmap_y}</p>
							</div>
						</div>
					)}
					{id === 'MDA_ideal' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Ideal Anesthesiologists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.mda_ideal_y}</p>
							</div>
						</div>
					)}
					{id === 'MDA_custom' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Custom Anesthesiologists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.mda_custom_y}</p>
							</div>
						</div>
					)}
					{id === 'CRNA_heatmap' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Concurrent Anesthetists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.crna_heatmap_y}</p>
							</div>
						</div>
					)}
					{id === 'CRNA_ideal' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Ideal Anesthetists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.crna_ideal_y}</p>
							</div>
						</div>
					)}
					{id === 'CRNA_custom' && (
						<div className='bg-blue-900 p-1 flex flex-col justify-between'>
							<p className='whitespace-nowrap text-left text-[0.5em] font-semibold text-white'>Custom Anesthetists</p>
							<div className='bg-blue-900 pt-1 flex justify-between'>
								<p className='text-right text-[0.5em] text-white opacity-80'>{datum?.crna_custom_y}</p>
							</div>
						</div>
					)}
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	id: string;
	datum?: { allocated: number; automatic_release: number; voluntary_release: number; row_name: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2, id } = this.props;
		return (
			<g>
				<VictoryLabel
					{...this.props}
					verticalAnchor='middle'
					renderInPortal
					dx={5}
					style={{ fontFamily: fontFamily, fontSize: 24 }}
				/>
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} id={id} />}
					style={{ fontSize: 12 }}
				/>
			</g>
		);
	}
}

export function AnesthesiaStaffing() {
	// const [mdTableData, setMDTableData] = useState<FacilityUtilizationDetailsResponse['data']>([]);
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data } = useSystem();
	const current_facility = data?.facilities?.find((f) => f.id === selectedFacility);
	const exportData: { day_of_week?: string | undefined }[] = [{}];
	const location = useLocation();
	const pathname = window.location.href;
	const url = new URL(pathname);
	const filterIdPassedInURL = url.searchParams.has('filter_id') ? url.searchParams.get('filter_id') : null;
	const { createToast } = useToast();
	const [showCost, setShowCost] = useState<boolean>(false);

	// Filters
	const {
		dateRange,
		daysOfWeek,
		rooms,
		addOns,
		serviceLines,
		encounterTypes,
		surgeons,
		anesthesiologists,
		anesthetists,
		percentile,
		heatmapMethod,
		currentPageLoaded,
		dropDowns,
		saveDropdown,
		clearFilters,
		metadata,
		resetFilters,
		filtersAreDirty,
		applyFilters,
		salaries,
		mdaCoverRoom,
		turnoverConcurrency,
		turnoverTimeThreshold,
	} = useFilters();

	const toggle_eight_hour_shift =
		dropDowns?.eight_hour_shift !== 'undefined' ? strToBool(dropDowns?.eight_hour_shift) : true;
	const toggle_ten_hour_shift = dropDowns?.ten_hour_shift !== 'undefined' ? strToBool(dropDowns?.ten_hour_shift) : true;
	const toggle_twelve_hour_shift =
		dropDowns?.twelve_hour_shift !== 'undefined' ? strToBool(dropDowns?.twelve_hour_shift) : true;
	const toggle_fourteen_hour_shift =
		dropDowns?.fourteen_hour_shift !== 'undefined' ? strToBool(dropDowns?.fourteen_hour_shift) : false;

	// the following drop down items have their own state because we want instant changes shown, this is done to prevent rubber-banding
	const selectedDayOfWeek = dropDowns?.carousel_day_of_week_selected ?? daysOfWeek?.applied[0];

	const staffingGrid = dropDowns.staffing_grid ?? [];
	const isMDOnly = current_facility?.medical_direction_model === 'md_only';
	const isCRNAOnly = current_facility?.medical_direction_model === 'crna_only';
	const isTeamCare = current_facility?.medical_direction_model === 'team_care';

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/anesthesia-staffing') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: anesthesiaCoverageOverview,
		isFetching,
		isError,
		error,
	} = useGetAnesthesiaCoverageQuery(
		filterIdPassedInURL
			? { filter_id: parseInt(filterIdPassedInURL) }
			: {
					facility_id: selectedFacility,
					filters: {
						days_of_week: daysOfWeek?.applied,
						rooms: rooms?.applied,
						add_ons: addOns?.applied,
						service_lines: serviceLines?.applied,
						surgeons: surgeons?.applied,
						encounter_types: encounterTypes?.applied,
						start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
						end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
						heatmap_method: heatmapMethod?.applied.name,
						percentile: percentile?.applied,
						mda_cover_room: mdaCoverRoom.applied,
						turnover_concurrency: turnoverConcurrency?.applied,
						turnover_threshold: turnoverTimeThreshold?.applied,
					},
					toggleStates: {
						medical_ratio: dropDowns.medical_ratio ?? 3,
						eight_hour_shift: toggle_eight_hour_shift,
						ten_hour_shift: toggle_ten_hour_shift,
						twelve_hour_shift: toggle_twelve_hour_shift,
						fourteen_hour_shift: toggle_fourteen_hour_shift,
					},
					staffingGrid: staffingGrid,
			  },
		{
			skip: skipRequest,
		}
	);

	const getNextDayOfWeek = (selectedDayOfWeek: DAYS_OF_WEEK) => {
		const index = daysOfWeek?.applied.indexOf(selectedDayOfWeek);
		if (index === daysOfWeek?.applied.length - 1) {
			return daysOfWeek?.applied[0];
		} else {
			return daysOfWeek?.applied[index + 1];
		}
	};

	// handler for clicking the "next" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handleNextClick = () => {
		const nextDOW = getNextDayOfWeek(selectedDayOfWeek as DAYS_OF_WEEK);
		dropDowns.update({ ...dropDowns, carousel_day_of_week_selected: nextDOW });
		saveDropdown({
			...dropDowns,
			carousel_day_of_week_selected: nextDOW,
		});
	};

	const getPrevDayOfWeek = (selectedDayOfWeek: DAYS_OF_WEEK) => {
		const index = daysOfWeek?.applied.indexOf(selectedDayOfWeek);
		if (index === 0) {
			return daysOfWeek?.applied[daysOfWeek?.applied.length - 1];
		} else {
			return daysOfWeek?.applied[index - 1];
		}
	};

	// handler for clicking the "prev" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handlePrevClick = () => {
		const nextDOW = getPrevDayOfWeek(selectedDayOfWeek as DAYS_OF_WEEK);
		dropDowns.update({ ...dropDowns, carousel_day_of_week_selected: nextDOW });
		saveDropdown({
			...dropDowns,
			carousel_day_of_week_selected: nextDOW,
		});
	};

	// When data from the endpoint changes or an error is encountered, update the
	// table data appropriately.
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}

			return;
		}
	}, [anesthesiaCoverageOverview, error, createToast]);

	// calculate y axis
	let crnaDataMaxY = 0;

	let mdaDataMaxY = 0;

	for (let i = 0; i < daysOfWeek?.applied.length; i++) {
		const crna_max =
			Math.ceil(
				Math.max(...(anesthesiaCoverageOverview?.data.crna_rr[daysOfWeek?.applied[i]]?.map((d) => d.y) ?? [1])) + 1
			) ?? 0;

		const mda_max =
			Math.ceil(
				Math.max(...(anesthesiaCoverageOverview?.data.mda_rr[daysOfWeek?.applied[i]]?.map((d) => d.y) ?? [1])) + 1
			) ?? 0;

		if (crna_max > crnaDataMaxY) {
			crnaDataMaxY = crna_max + 1;
		}

		if (mda_max > mdaDataMaxY) {
			mdaDataMaxY = mda_max + 1;
		}

		if (!isMDOnly) {
			mdaDataMaxY = crnaDataMaxY;
		}
	}

	// Overlays users custom staffing grid
	const userDefinedStaffingLineCRNA = new Array(23).fill(0);

	dropDowns.staffing_grid?.forEach((staffing) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedStaffingLineCRNA[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];

			if (crnaDataMaxY < staffing[selectedDayOfWeek as keyof StaffingGridItem]) {
				crnaDataMaxY = staffing[selectedDayOfWeek as keyof StaffingGridItem] + 1;
			}
		}
	});

	const userDefinedStaffingLineMDA = new Array(23).fill(0);

	anesthesiaCoverageOverview?.data.mda_staffing_grid?.forEach((staffing) => {
		const hours = fillArray(Math.round(staffing.startMinute / 60), Math.round(staffing.endMinute / 60));
		for (let i = 0; i < hours.length; i++) {
			userDefinedStaffingLineMDA[hours[i]] = staffing[selectedDayOfWeek as keyof StaffingGridItem];

			if (mdaDataMaxY < staffing[selectedDayOfWeek as keyof StaffingGridItem]) {
				mdaDataMaxY = staffing[selectedDayOfWeek as keyof StaffingGridItem] + 1;
			}
		}
	});

	const disableCostButton = isMDOnly
		? !salaries.applied.anesthesiologist
		: !salaries.applied.anesthesiologist || !salaries.applied.anesthetist;

	return (
		<>
			<PageHeading>Anesthesia Staffing</PageHeading>
			<div className='flex justify-end pb-3 overflow-auto'>
				<div>
					<Tooltip
						content={
							disableCostButton
								? 'To enable this button, add salary information for providers under Anesthesia Settings'
								: showCost
								? 'Display FTE counts in the Coverage by Shift Type table(s)'
								: 'Display Staffing cost in the Coverage by Shift Type table(s)'
						}
					>
						<Button
							sizeX='square'
							sizeY='md'
							variant={showCost ? 'primary' : 'primary-ghost'}
							className='mr-2'
							onClick={() => setShowCost(!showCost)}
							disabled={disableCostButton}
						>
							<span className='material-symbols-outlined'>attach_money</span>
						</Button>
					</Tooltip>
				</div>
				<StaffingGrid>
					<Button sizeX='md' sizeY='md' variant='primary-ghost' className='mr-2'>
						<div className='flex items-center'>
							<span className='material-symbols-outlined mr-2'>calendar_view_month</span>
							<p>Custom</p>
						</div>
					</Button>
				</StaffingGrid>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields
						fields={[
							'dateRange',
							'daysOfWeek',
							'rooms',
							'serviceLines',
							'addOns',
							'encounterTypes',
							// 'surgeons',
							// 'anesthesiologists',
							// 'anesthetists',
							'heatmapMethod',
							'mdaCoverRoom',
							'percentile',
							'salaries',
							'turnoverConcurrency',
							'turnoverTimeThreshold',
						]}
						dateRangeLimit={1}
					/>
				</Drawer>
				<div>
					<Button sizeX='md' sizeY='md' variant='primary' disabled>
						<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData ?? []}>
							Export CSV
						</ExportButton>
					</Button>
				</div>
			</div>
			<Panel
				unStyledTitle={
					<div className='flex flex-col justify-between w-[40em] xl:flex-row'>
						<div className='flex justify-between'>
							<MedicalRatio dropDowns={dropDowns} saveDropdown={saveDropdown} isMDOnly={isMDOnly} isCRNAOnly={isCRNAOnly} />
						</div>
						<div className='flex justify-between'>
							<div>
								<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Shift Type Options</p>
								<div className='flex mr-5'>
									<ShiftToggleButton
										text='8 hr'
										toggle={(isActive) => {
											if (isActive || toggle_ten_hour_shift || toggle_twelve_hour_shift || toggle_fourteen_hour_shift) {
												dropDowns.update({
													...dropDowns,
													eight_hour_shift: boolToStr(isActive),
												});
												saveDropdown({
													...dropDowns,
													eight_hour_shift: boolToStr(isActive),
												});
											}
										}}
										isActive={toggle_eight_hour_shift}
										firstItem={true}
									/>

									<ShiftToggleButton
										text='10 hr'
										toggle={(isActive) => {
											if (toggle_eight_hour_shift || isActive || toggle_twelve_hour_shift || toggle_fourteen_hour_shift) {
												dropDowns.update({
													...dropDowns,
													ten_hour_shift: boolToStr(isActive),
												});
												saveDropdown({
													...dropDowns,
													ten_hour_shift: boolToStr(isActive),
												});
											}
										}}
										isActive={toggle_ten_hour_shift}
									/>
									<ShiftToggleButton
										text='12 hr'
										toggle={(isActive) => {
											dropDowns.update({
												...dropDowns,
												twelve_hour_shift: boolToStr(isActive),
											});
											saveDropdown({
												...dropDowns,
												twelve_hour_shift: boolToStr(isActive),
											});
										}}
										isActive={toggle_twelve_hour_shift}
									/>
									<ShiftToggleButton
										text='14 hr'
										toggle={(isActive) => {
											dropDowns.update({
												...dropDowns,
												fourteen_hour_shift: boolToStr(isActive),
											});
											saveDropdown({
												...dropDowns,
												fourteen_hour_shift: boolToStr(isActive),
											});
										}}
										isActive={toggle_fourteen_hour_shift}
									/>
								</div>
							</div>
						</div>
						<div className='flex justify-between'>
							<div>
								<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Model Type</p>
								<div className='flex mr-5'>
									<LegendToggleButton
										text='Heatmap'
										disabled={!current_facility?.has_intraop}
										color={<div className='bg-[#4A55A2] rounded-full w-3 h-3 block mr-2'></div>}
										toggle={(isActive) => {
											//setLines((prevState) => ({ ...prevState, heatmap: isActive }));
											dropDowns.update({ ...dropDowns, legend: { ...dropDowns.legend, heatmap: isActive } });
											saveDropdown({ ...dropDowns, legend: { ...dropDowns.legend, heatmap: isActive } });
										}}
										isActive={!!dropDowns.legend?.heatmap}
										firstItem={true}
									/>
									<LegendToggleButton
										text='Ideal'
										disabled={!current_facility?.has_intraop}
										color={<div className='bg-[#A0BFE0] rounded-full w-3 h-3 block mr-2'></div>}
										toggle={(isActive) => {
											//setLines((prevState) => ({ ...prevState, ideal: isActive }));
											dropDowns.update({ ...dropDowns, legend: { ...dropDowns.legend, ideal: isActive } });
											saveDropdown({ ...dropDowns, legend: { ...dropDowns.legend, ideal: isActive } });
										}}
										isActive={!!dropDowns.legend?.ideal}
									/>
									<LegendToggleButton
										text='Custom'
										color={<div className='bg-[#C4B0FF] rounded-full w-3 h-3 block mr-2'></div>}
										toggle={(isActive) => {
											//setLines((prevState) => ({ ...prevState, custom: isActive }));
											dropDowns.update({ ...dropDowns, legend: { ...dropDowns.legend, custom: isActive } });
											saveDropdown({ ...dropDowns, legend: { ...dropDowns.legend, custom: isActive } });
										}}
										isActive={!!dropDowns.legend?.custom || !current_facility?.has_intraop}
									/>
								</div>
							</div>
						</div>
					</div>
				}
				headerContentRight={
					<div className='flex mt-2'>
						<div className='flex text-center border border-blue-500 items-center justify-center gap-0 mr-4'>
							<Button sizeX='square' sizeY='sm' variant='transparent' className='text-blue-500' onClick={handlePrevClick}>
								<span className='material-symbol h-fit'>navigate_before</span>
							</Button>
							<div className='text-p2 text-blue-500 w-24'>{selectedDayOfWeek}</div>
							<Button sizeX='square' sizeY='sm' variant='transparent' className='text-blue-500' onClick={handleNextClick}>
								<span className='material-symbol h-fit'>navigate_next</span>
							</Button>
						</div>
						<div className='flex text-center bg-blue-500 items-center justify-center gap-0 rounded-md'>
							<span className='text-b3 font-semibold px-6 text-white'>
								{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
							</span>
						</div>
					</div>
				}
				goToHelpID='heatmap'
			>
				{(isFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}
				<Heatmap
					title={''}
					showFullYAxisName={true}
					// colorScheme={props.colorScheme}
					// customCellPadding={props.customPadding}
					datumFormat={(datum) => String(Math.round(parseFloat(String(datum))))}
					axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
					data={
						isError
							? []
							: (anesthesiaCoverageOverview?.data.rr.length
									? [anesthesiaCoverageOverview?.data.rr[daysOfWeek?.applied.indexOf(selectedDayOfWeek as DAYS_OF_WEEK)]]
									: anesthesiaCoverageOverview?.data.rr) ?? []
					}
					emptyMessage='This heatmap has no data to display based on the provided filters.'
				/>
				<div className='flex'>
					{(isMDOnly || isTeamCare) && (
						<div className='basis-full'>
							<VictoryChart
								domain={{ y: [0, mdaDataMaxY] }}
								width={!isTeamCare ? window.innerWidth / 2 : undefined}
								containerComponent={<VictoryVoronoiContainer />}
							>
								<VictoryAxis
									dependentAxis
									label='Est. No. of Anesthesiologists'
									tickCount={6}
									tickValues={fillArray(0, mdaDataMaxY)}
									style={{
										axis: { stroke: 'hsl(0 0% 90%)' },
										grid: { stroke: 'hsl(0 0% 90%)' },
										tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
										axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
									}}
								/>
								<VictoryAxis
									label='Hour of Day'
									tickCount={isTeamCare ? 12 : undefined}
									tickValues={fillArray(0, 23)}
									style={{
										axis: { stroke: 'hsl(0 0% 90%)' },
										tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
										axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
									}}
								/>
								<defs>
									<linearGradient id='myGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
										<stop offset='0%' stopColor={lightGradient} />
										<stop offset='100%' stopColor={darkGradient} />
									</linearGradient>
								</defs>

								{dropDowns.legend?.heatmap && (
									<VictoryArea
										style={{
											data: { fill: 'url(#myGradient)' },
											parent: { border: '1px solid #bcbcbc' },
										}}
										data={
											anesthesiaCoverageOverview?.data.mda_rr[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													mda_heatmap_y: d.y,
												};
											}) ?? []
										}
										interpolation='monotoneX'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'MDA_heatmap'} />} />}
									/>
								)}
								{dropDowns.legend?.ideal && (
									<VictoryArea
										style={{
											data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
											parent: { border: '1px solid ##bcbcbc' },
										}}
										data={anesthesiaCoverageOverview?.data.mda_coverage[selectedDayOfWeek].map((d, i) => {
											return {
												x: i,
												y: d.y,
												mda_ideal_y: d.y,
											};
										})}
										interpolation='stepAfter'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'MDA_ideal'} />} />}
									/>
								)}
								{dropDowns.legend?.custom && (
									<VictoryArea
										style={{
											data: { stroke: customStroke, strokeWidth: 1.5, fill: customFill },
											parent: { border: '1px solid #bcbcbc' },
										}}
										data={
											isMDOnly
												? userDefinedStaffingLineCRNA.slice(0, 24).map((d, i) => {
														return {
															x: i,
															y: d,
															mda_custom_y: d.y,
														};
												  })
												: userDefinedStaffingLineMDA.slice(0, 24).map((d, i) => {
														return {
															x: i,
															y: d,
															mda_custom_y: d.y,
														};
												  })
										}
										interpolation='stepAfter'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'MDA_custom'} />} />}
									/>
								)}
								<VictoryLabel
									text='Anesthesiologist Coverage'
									textAnchor='middle'
									x={!isTeamCare ? window.innerWidth / 4.1 : 225}
									y={18}
									style={{
										fontFamily: 'Inter',
										fontSize: 10,
										fill: '#8395a7',
									}}
								/>
							</VictoryChart>
						</div>
					)}
					{(isCRNAOnly || isTeamCare) && (
						<div className='basis-full'>
							<VictoryChart
								domain={{ y: [0, crnaDataMaxY] }}
								containerComponent={<VictoryVoronoiContainer />}
								width={!isTeamCare ? window.innerWidth / 2 : undefined}
							>
								<VictoryAxis
									dependentAxis
									label='Est. No. of Anesthetists'
									tickCount={6}
									tickValues={fillArray(0, crnaDataMaxY)}
									style={{
										axis: { stroke: 'hsl(0 0% 90%)' },
										grid: { stroke: 'hsl(0 0% 90%)' },
										tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
										axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
									}}
								/>
								<VictoryAxis
									label='Hour of Day'
									tickCount={12}
									tickValues={fillArray(0, 23)}
									style={{
										axis: { stroke: 'hsl(0 0% 90%)' },
										tickLabels: { fontFamily: 'Inter', fontSize: 8, fill: '#8395a7' },
										axisLabel: { fontFamily: 'Inter', fontSize: 9, fill: '#8395a7' },
									}}
								/>
								<defs>
									<linearGradient id='myGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
										<stop offset='0%' stopColor={lightGradient} />
										<stop offset='100%' stopColor={darkGradient} />
									</linearGradient>
								</defs>
								{dropDowns.legend?.heatmap && (
									<VictoryArea
										style={{
											data: { fill: 'url(#myGradient)' },
											parent: { border: '1px solid #bcbcbc' },
										}}
										data={
											anesthesiaCoverageOverview?.data.crna_rr[selectedDayOfWeek].map((d, i) => {
												return {
													x: i,
													y: d.y,
													crna_heatmap_y: d.y,
												};
											}) ?? []
										}
										interpolation='monotoneX'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'CRNA_heatmap'} />} />}
									/>
								)}
								{dropDowns.legend?.ideal && (
									<VictoryArea
										style={{
											data: { stroke: idealStroke, strokeWidth: 1.5, fill: idealFill },
											parent: { border: '1px solid ##bcbcbc' },
										}}
										data={anesthesiaCoverageOverview?.data.crna_coverage[selectedDayOfWeek].map((d, i) => {
											return {
												x: i,
												y: d.y,
												crna_ideal_y: d.y,
											};
										})}
										interpolation='stepAfter'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'CRNA_ideal'} />} />}
									/>
								)}

								{dropDowns.legend?.custom && (
									<VictoryArea
										style={{
											data: { stroke: customStroke, strokeWidth: 1.5, fill: customFill },
											parent: { border: '1px solid #bcbcbc' },
										}}
										data={userDefinedStaffingLineCRNA.slice(0, 24).map((d, i) => {
											return {
												x: i,
												y: d,
												crna_custom_y: d.y,
											};
										})}
										interpolation='stepAfter'
										labels={({ datum }: any) => ``}
										labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout id={'CRNA_custom'} />} />}
									/>
								)}

								<VictoryLabel
									text='Anesthetist Coverage'
									textAnchor='middle'
									x={!isTeamCare ? window.innerWidth / 4.1 : 225}
									y={18}
									style={{
										fontFamily: 'Inter',
										fontSize: 10,
										fill: '#8395a7',
									}}
								/>
							</VictoryChart>
						</div>
					)}
				</div>
			</Panel>
			<div className='py-4'></div>
			{!isCRNAOnly && (
				<DataTable
					title='Anesthesiologist Coverage by Shift Type'
					disablePagination
					omittedColumns={['model_type']}
					disableRowCounter
					addCommaToSums={showCost}
					addPrefixToSums={showCost ? '$' : ''}
					goToHelpID={'fliprooms'}
					headerContentCenterAlignment='left'
					columns={[
						{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
						{
							accessorKey: 'shift_fte',
							header: 'Primetime FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthesiologist ?? 1) * row.original.shift_fte).toFixed(0)}`)
									: row.original.shift_fte,
						},
						{
							accessorKey: 'after_hours_fte',
							header: 'Non-Primetime FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthesiologist ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
									: row.original.after_hours_fte,
						},
						{
							accessorKey: 'total_fte',
							header: 'Total FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthesiologist ?? 1) * row.original.total_fte).toFixed(0)}`)
									: row.original.total_fte,
						},
					]}
					data={anesthesiaCoverageOverview?.data.mda_fte_coverage_rollup ?? []}
					rowSubview={(row) => (
						<>
							<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
								Anesthesiologist FTE Details
							</h3>
							<DataTable
								title='Anesthesiologist FTE Details'
								minimalStyle
								omittedColumns={['day_of_week', 'day_of_week_int']}
								defaultSort={{ id: 'day_of_week_int', desc: false }}
								displayRowSums
								addCommaToSums={showCost}
								addPrefixToSums={showCost ? '$' : ''}
								displayColSums
								disablePagination
								disableRowCounter
								goToHelpID={'fliprooms'}
								headerContentCenterAlignment='left'
								columns={findColumnHeaders(row.original.details, showCost ? salaries.applied.anesthesiologist ?? 1 : 1, 'MDA')}
								data={addSalaryToValues(row.original.details, showCost ? salaries.applied.anesthesiologist ?? 1 : 1) ?? []}
							/>
						</>
					)}
				/>
			)}
			<div className='py-4'></div>
			{!isMDOnly && (
				<DataTable
					title='Anesthetist Coverage by Shift Type'
					tooltipContent={``}
					goToHelpID={'heatmap'}
					headerContentCenterAlignment='left'
					columns={[
						{ accessorKey: 'model_type', header: 'Model Type', enableGlobalFilter: false },
						{
							accessorKey: 'shift_fte',
							header: 'Primetime FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthetist ?? 1) * row.original.shift_fte).toFixed(0)}`)
									: row.original.shift_fte,
						},
						{
							accessorKey: 'after_hours_fte',
							header: 'Non-Primetime FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthetist ?? 1) * row.original.after_hours_fte).toFixed(0)}`)
									: row.original.after_hours_fte,
						},
						{
							accessorKey: 'total_fte',
							header: 'Total FTE',
							enableGlobalFilter: false,
							cell: ({ row }) =>
								showCost
									? numberWithCommas(`$${((salaries.applied.anesthetist ?? 1) * row.original.total_fte).toFixed(0)}`)
									: row.original.total_fte,
						},
					]}
					data={anesthesiaCoverageOverview?.data.crna_fte_coverage_rollup ?? []}
					disablePagination
					disableRowCounter
					rowSubview={(row) => (
						<>
							<h3 className='whitespace-nowrap pb-4 pl-2 text-left text-bluegray-900 uppercase text-p2 font-semibold'>
								Anesthetist FTE Details
							</h3>
							<DataTable
								title='Anesthetist FTE Details'
								tooltipContent={``}
								defaultSort={{ id: 'day_of_week_int', desc: false }}
								omittedColumns={['day_of_week', 'day_of_week_int']}
								displayRowSums
								displayColSums
								addCommaToSums={showCost}
								addPrefixToSums={showCost ? '$' : ''}
								minimalStyle
								goToHelpID={'heatmap'}
								headerContentCenterAlignment='left'
								columns={findColumnHeaders(row.original.details, showCost ? salaries.applied.anesthetist ?? 1 : 1, 'CRNA')}
								data={addSalaryToValues(row.original.details, showCost ? salaries.applied.anesthetist ?? 1 : 1) ?? []}
								disablePagination
								disableRowCounter
							/>
						</>
					)}
				/>
			)}
		</>
	);
}

interface intDef {
	header: string;
	accessorKey: string;
	enableGlobalFilter?: boolean;
	meta?: { headerClass: string; bodyClass: string };
	cell?: ({ row }: any) => string;
}

function MedicalRatio({
	dropDowns,
	saveDropdown,
	isMDOnly,
	isCRNAOnly,
}: {
	dropDowns: FilterContextField<dropDowns>;
	saveDropdown: (newState: dropDowns, refreshTag?: 'Anesthesia' | undefined) => void;
	isMDOnly: boolean;
	isCRNAOnly: boolean;
}) {
	// used to handle medical ratio number toggle
	const [medicalRatioDenom, setMedicalRatioDenom] = useState(dropDowns?.medical_ratio ?? 3);
	const [isMedicalRatioDirty, setIsMedicalRatioDirty] = useState(false);

	useEffect(() => {
		setMedicalRatioDenom(dropDowns?.medical_ratio ?? 3);
	}, [dropDowns?.medical_ratio]);

	return (
		<div className='mr-5'>
			<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary whitespace-nowrap'>
				Medical Direction Ratio
			</p>
			<div className='flex flex-nowrap mr-5 justify-start items-center mt-[0.4em]'>
				<p className='text-blue-500 text-p2 font-semibold mr-1 whitespace-nowrap'>1 :</p>
				<NumberField
					value={dropDowns.medical_ratio}
					min={1}
					max={10}
					disabled={isMDOnly || isCRNAOnly}
					className='mr-1 h-[1.8em] w-[4em] text-p2 font-semibold border border-blue-400 text-blue-500'
					step={1}
					showRangeInput={false}
					hideLabel={true}
					label={''}
					onChange={(e) => {
						if (!e.currentTarget.value) return;
						setIsMedicalRatioDirty(true);
						setMedicalRatioDenom(parseInt(e.currentTarget.value));
					}}
				/>
				{isMedicalRatioDirty && (
					<div
						onClick={() => {
							dropDowns.update({ ...dropDowns, medical_ratio: medicalRatioDenom });
							saveDropdown(
								{
									...dropDowns,
									medical_ratio: medicalRatioDenom,
								},
								'Anesthesia'
							);
							setIsMedicalRatioDirty(false);
						}}
						className='hover:bg-blue-500 cursor-pointer text-blue-500 hover:text-white rounded-sm flex flex-col justify-center text-center h-[1.35em] w-[4em] border border-blue-400'
					>
						<p className='text-[0.7em] pb-0.5'>Apply</p>
					</div>
				)}
			</div>
		</div>
	);
}

function addSalaryToValues(obj: Shift[], salary: number) {
	if (salary === 1) {
		return obj;
	}

	const ignoredColumns = ['Row Sum', 'day_of_week', 'day_of_week_int'];

	return obj.map((elem) => {
		const keys = Object.keys(elem).filter((key) => !ignoredColumns.includes(key));
		const newObj = { ...elem };
		for (let i = 0; i < keys.length; i++) {
			newObj[keys[i]] = parseInt(`${Number(elem[keys[i]]) * salary}`);
		}
		return newObj;
	});
}

function findColumnHeaders(obj: Shift[] | undefined, salary: number, tableType: string) {
	if (!obj) {
		return [];
	}

	const carry: intDef[] = [
		{
			header: 'Day of Week',
			accessorKey: 'day_of_week_int',
			enableGlobalFilter: true,
			cell: ({ row }: any) => `${row?.original.day_of_week}`,
		},
	];

	if (tableType === 'CRNA') {
		for (const property in obj[0]) {
			if (!property.includes('day_of_week')) {
				if (property !== 'Row Sum') {
					carry.push({
						header: property,
						accessorKey: property,
						enableGlobalFilter: true,
						cell: ({ row }: any) =>
							salary > 1 ? `$${numberWithCommas(`${row?.original[property]}`)}` : `${row?.original[property].toFixed(2)}`,
					});
				}
			}
		}
	} else {
		for (const property in obj[0]) {
			if (!property.includes('day_of_week')) {
				if (property !== 'Row Sum') {
					carry.push({
						header: property,
						accessorKey: property,
						enableGlobalFilter: true,
						cell: ({ row }: any) =>
							salary > 1 ? `$${numberWithCommas(`${row?.original[property]}`)}` : `${row?.original[property].toFixed(2)}`,
					});
				}
			}
		}
	}
	return carry.sort(compare);
}

function compare(a: intDef, b: intDef) {
	// Split the 'time' string into two parts
	const aTimes = a.header.split('-').map(Number);
	const bTimes = b.header.split('-').map(Number);

	// Compare the first part
	if (aTimes[0] < bTimes[0]) {
		return -1;
	}
	if (aTimes[0] > bTimes[0]) {
		return 1;
	}

	// If the first parts are equal, compare the second part
	if (aTimes[1] < bTimes[1]) {
		return -1;
	}
	if (aTimes[1] > bTimes[1]) {
		return 1;
	}

	return 0;
}

export function ShiftToggleButton({
	text,
	toggle,
	activeText,
	bgColor,
	isActive,
	firstItem,
	disabled,
}: {
	text: string;
	toggle: (toggleState: boolean) => void;
	activeText?: string;
	bgColor?: 'blue' | 'green';
	isActive: boolean;
	firstItem?: boolean;
	disabled?: boolean;
}) {
	return (
		<div
			onClick={() => {
				toggle(!isActive);
			}}
			className={classNames(
				{
					'cursor-pointer bg-transparent text-blue-500': !isActive && !bgColor && !disabled,
					'cursor-pointer bg-blue-500 text-white': isActive && !bgColor && !disabled,
					'cursor-pointer bg-transparent text-green-600': !isActive && bgColor === 'green',
					'cursor-pointer bg-green-600 text-white': isActive && bgColor === 'green',
					'cursor-pointer border border-blue-400 ': bgColor === undefined || bgColor === 'blue',
					'cursor-pointer border border-green-600 ': bgColor === 'green',
					'cursor-pointer border-l-0': !firstItem,
					'cursor-not-allowed opacity-80 text-gray-500 bg-gray-100': disabled,
				},
				'text-p3 px-3 py-1 text-center select-none whitespace-nowrap'
			)}
		>
			{isActive && activeText ? activeText : text}
		</div>
	);
}

export function LegendToggleButton({
	text,
	toggle,
	activeText,
	disabled,
	bgColor,
	isActive,
	firstItem,
	color,
}: {
	text: string;
	toggle: (toggleState: boolean) => void;
	activeText?: string;
	bgColor?: 'blue' | 'green';
	isActive: boolean;
	firstItem?: boolean;
	color?: ReactNode;
	disabled?: boolean;
}) {
	return (
		<div
			onClick={() => {
				toggle(!isActive);
			}}
			className={classNames(
				{
					'border border-blue-500 border-b-1 text-black': !isActive && !bgColor && !disabled,
					'border border-b-blue-500 border-b-4 text-black': isActive && !bgColor && !disabled,
					'bg-green-600 text-white': isActive && bgColor === 'green',
					'border border-blue-400 ': bgColor === undefined || bgColor === 'blue',
					'border border-green-600 ': bgColor === 'green',
					'border-l-0': !firstItem,
					'cursor-not-allowed opacity-80 text-gray-500 bg-gray-100': disabled,
				},
				'text-p3 px-3 py-1 cursor-pointer text-center select-none flex items-center justify-center h-[2.1em]'
			)}
		>
			<div className='flex items-center'>
				{color}
				<p>{text}</p>
			</div>
		</div>
	);
}

function strToBool(s: string | undefined): boolean {
	return s === 'true';
}

function boolToStr(b: boolean): string {
	return b ? 'true' : 'false';
}

function fillArray(n: number, m: number) {
	return Array.from({ length: m - n + 1 }, (value, index) => n + index);
}

export default AnesthesiaStaffing;
