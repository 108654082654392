import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';

import {
	AccountSettings,
	Analytics,
	AnesthesiaProvider,
	AnesthesiaSettings,
	BlockSettings,
	FacilityConfiguration,
	FacilitySettings,
	NurseStaffingSettings,
	Room,
	Surgeon,
	UserProfile,
} from 'pages/Settings/Tabs';
import { PageHeading } from 'components';
import { checkLicenseBits, userIs } from 'utils';
import { FacilityLicense, UserRole } from 'models';
import { useAppSelector, useSystem } from 'store';

import { ScheduleSettings } from './Tabs/ScheduleSettings';

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);
const tabContentClassname = 'py-4';

export function Settings() {
	const { data } = useSystem();
	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);
	const { data: facility_data } = useSystem();
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const currentFacility = facility_data?.facilities?.filter((f) => f.id === selectedFacility)[0];
	const has_anesthesia = checkLicenseBits(currentFacility?.license, FacilityLicense.anesthesia);

	// defaults to different tab based on URL
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	const paramValue = (url && getDefaultTab(url.pathname)) ?? 'user';
	const pageTitle = (url && url.pathname.split('/')[1].charAt(0).toUpperCase() + url.pathname.slice(2)) ?? 'Settings';

	function getDefaultTab(path: string) {
		if (path === '/account') {
			return 'user';
		} else if (path === '/settings') {
			return 'facility-settings';
		} else if (path === '/aliases') {
			return 'surgeons';
		} else if (path === '/admin') {
			return 'facility-config';
		}

		return 'user';
	}

	return (
		<div>
			<PageHeading>{pageTitle}</PageHeading>

			{url && url.pathname === '/analytics' && <Analytics />}

			{url && url.pathname === '/account' && (
				<>
					<Tabs.Root defaultValue={paramValue} orientation='vertical'>
						<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
							<Tabs.Trigger value='user' className={tabTriggerClassname}>
								User Profile
							</Tabs.Trigger>
							<Tabs.Trigger value='account' className={tabTriggerClassname}>
								Account Settings
							</Tabs.Trigger>
						</Tabs.List>
						<Tabs.Content value='user' className={tabContentClassname}>
							<UserProfile />
						</Tabs.Content>
						<Tabs.Content value='account' className={tabContentClassname}>
							<AccountSettings />
						</Tabs.Content>
					</Tabs.Root>
				</>
			)}

			{url && url.pathname === '/settings' && (
				<>
					<Tabs.Root defaultValue={paramValue} orientation='vertical'>
						<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
							<Tabs.Trigger value='facility-settings' className={tabTriggerClassname}>
								Facility Settings
							</Tabs.Trigger>
							<Tabs.Trigger value='schedule-settings' className={tabTriggerClassname}>
								Schedule Settings
							</Tabs.Trigger>
							<Tabs.Trigger value='block' className={tabTriggerClassname}>
								Block Settings
							</Tabs.Trigger>
							<Tabs.Trigger value='anesthesia' className={tabTriggerClassname}>
								Anesthesia Settings
							</Tabs.Trigger>
							<Tabs.Trigger value='nurse-staffing' className={tabTriggerClassname}>
								Nurse Staffing Settings
							</Tabs.Trigger>
						</Tabs.List>
						<Tabs.Content value='facility-settings' className={tabContentClassname}>
							<FacilitySettings />
						</Tabs.Content>
						<Tabs.Content value='schedule-settings' className={tabContentClassname}>
							<ScheduleSettings />
						</Tabs.Content>
						<Tabs.Content value='block' className={tabContentClassname}>
							<BlockSettings />
						</Tabs.Content>
						<Tabs.Content value='anesthesia' className={tabContentClassname}>
							<AnesthesiaSettings />
						</Tabs.Content>
						<Tabs.Content value='nurse-staffing' className={tabContentClassname}>
							<NurseStaffingSettings />
						</Tabs.Content>
					</Tabs.Root>
				</>
			)}
			{url && url.pathname === '/aliases' && (
				<>
					<Tabs.Root defaultValue={paramValue} orientation='vertical'>
						<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
							<Tabs.Trigger value='surgeons' className={tabTriggerClassname}>
								Surgeons
							</Tabs.Trigger>
							<Tabs.Trigger value='rooms' className={tabTriggerClassname}>
								Rooms
							</Tabs.Trigger>
							{has_anesthesia && (
								<Tabs.Trigger value='anesthesia_provider' className={tabTriggerClassname}>
									Anesthesia Provider
								</Tabs.Trigger>
							)}
						</Tabs.List>
						<Tabs.Content value='surgeons' className={tabContentClassname}>
							<Surgeon />
						</Tabs.Content>
						<Tabs.Content value='rooms' className={tabContentClassname}>
							<Room />
						</Tabs.Content>
						<Tabs.Content value='block' className={tabContentClassname}>
							<BlockSettings />
						</Tabs.Content>
						<Tabs.Content value='anesthesia_provider' className={tabContentClassname}>
							<AnesthesiaProvider />
						</Tabs.Content>
					</Tabs.Root>
				</>
			)}
			{url && url.pathname === '/admin' && (
				<Tabs.Root defaultValue={paramValue} orientation='vertical'>
					<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
						<Tabs.Trigger value='facility-config' className={tabTriggerClassname}>
							Facility Configuration
						</Tabs.Trigger>
					</Tabs.List>
					<Tabs.Content value='facility-config' className={tabContentClassname}>
						<FacilityConfiguration />
					</Tabs.Content>
				</Tabs.Root>
			)}
		</div>
	);
}

export default Settings;
