import { useRef, useState } from 'react';
import { inputCSS } from 'react-select/dist/declarations/src/components/Input';

import {
	getQueryError,
	handleApiResponse,
	useAppSelector,
	useGetSystemsQuery,
	useSetArtificialFacilityMutation,
	useSystem,
} from 'store';
import { Facility } from 'models';
import { FacilityConfigAccordionItem } from 'pages/Settings/Tabs';
import { Accordion, Button, Dialog, MultiSelect, TextField, WorkingIndicator } from 'components';
import { inputFieldRegex } from 'utils';
import { useToast } from 'context';

export function FacilityConfiguration() {
	// get the list of facilities under the current selected system
	const { isFetching } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});

	const n_facilities = data?.facilities ? data?.facilities.length : 0;

	return (
		<div>
			{data?.facilities?.map((facility: Facility, indx: number) => (
				<FacilityConfigAccordionItem
					key={indx}
					facility={facility}
					otherFacilities={data?.facilities?.filter(
						(facility_data: Facility) => facility_data.id !== facility.id && facility_data.field_mapping !== '{}'
					)}
				/>
			))}
			<FacilityConfigAccordionItem
				otherFacilities={data?.facilities?.filter((facility_data: Facility) => facility_data.field_mapping !== '{}') ?? []}
			/>
			{n_facilities > 1 && (
				<ArtificialFacilityConfiguration facilities={data?.facilities ?? []}></ArtificialFacilityConfiguration>
			)}
		</div>
	);
}

export function ArtificialFacilityConfiguration({ facilities }: { facilities: Array<Facility> }) {
	const [selectedFacilities, setSelectedFacilities] = useState<{ value: number; label: string }[]>([]);
	const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
	const [facilityName, setFacilityName] = useState<string>('');
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [setFacility] = useSetArtificialFacilityMutation();
	const { createToast } = useToast();

	// used to scroll drop down into view
	const ref = useRef<null | HTMLDivElement>(null);
	const handleClick = () => {
		ref.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const submit = async () => {
		setButtonLoading(true);
		const response = await setFacility({
			healthsystem_id: facilities[0].healthsystem_id,
			name: facilityName,
			children: selectedFacilities.map((facility) => facility.value),
		});
		handleApiResponse(response, {
			success: (data) => {
				createToast({
					title: `The combined facility was successfully created.`,
					variant: 'success',
				});
				setFacilityName('');
				setSelectedFacilities([]);
				setButtonLoading(false);
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
				setButtonLoading(false);
			},
		});
	};

	return (
		<Accordion type='multiple' itemStyle='dark' className='mb-80'>
			<Accordion.Item value={'+ Add new combined facility'}>
				<section>
					<div ref={ref} className='pt-10 grid lg:grid-cols-3 lg:w-3/4 gap-x-14 sm:grid-cols-1 sm:max-w-max'>
						<section className=''>
							<p className='font-semibold text-h6 pt-2 pb-2 uppercase'>Combined Facility</p>

							<div className=''>
								<p className='text-p3 text-gray-400 py-1 italic'>
									Combined facilities are a combination of physical facilities.
								</p>
							</div>
						</section>
						<div>
							<TextField
								onChange={(event) => {
									if (inputFieldRegex.test(event.target.value)) {
										setErrorMessage(undefined);
									} else {
										setErrorMessage('Please enter a valid facility name');
									}

									setFacilityName(event.target.value);
								}}
								label='Combined Facility Name'
								errorMessage={errorMessage}
								value={facilityName}
							/>
							<p className='text-p3 text-gray-400 py-1 italic'>This is how the facility name will be displayed in Merlin</p>
						</div>
						<div className='flex flex-col'>
							<div onClick={handleClick}>
								{' '}
								<MultiSelect
									label='Child Facilities'
									options={facilities
										.filter((facility) => !facility.name.includes('*'))
										.map((facility) => ({
											value: facility.id,
											label: facility.intraop_facility_name_alias ? facility.intraop_facility_name_alias : facility.name,
										}))}
									onChange={(selections) => {
										setSelectedFacilities(selections.map((selection) => ({ value: selection.value, label: selection.label })));
									}}
									value={selectedFacilities}
								/>
							</div>
							<div className='flex flex-wrap mt-5 overflow-y-auto w-96 max-h-32'>
								{selectedFacilities.map((facility, i) => (
									<p className='px-3 py-2 bg-blue-100 text-bluegray-600 h-fit w-fit m-1 rounded-sm whitespace-nowrap' key={i}>
										{facility.label}
									</p>
								))}
							</div>
						</div>
					</div>
				</section>
				<div className='flex justify-end pt-8'>
					<Button
						variant='primary'
						sizeX='md'
						sizeY='md'
						disabled={!(!errorMessage && facilityName.length > 1 && selectedFacilities.length > 1)}
						isWorking={buttonLoading}
						type='submit'
						onClick={() => {
							submit();
						}}
					>
						Create
					</Button>
				</div>
			</Accordion.Item>
		</Accordion>
	);
}

export default FacilityConfiguration;
