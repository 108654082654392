import * as RadixAccordion from '@radix-ui/react-accordion';
import { Children, createContext, isValidElement } from 'react';
import classNames from 'classnames';

// Create a context that will contain any values we want to track across both
// the Accordion and its subcomponents.
type AccordionContextType = { itemStyle: 'bordered' | 'contained' | 'dark' | 'yellow' };
const defaultAccordionItemStyle: AccordionContextType['itemStyle'] = 'bordered';
const AccordionContext = createContext<AccordionContextType>({ itemStyle: defaultAccordionItemStyle });

type AccordionProps = (RadixAccordion.AccordionSingleProps | RadixAccordion.AccordionMultipleProps) & {
	/** The style of interactive panels to display within this accordion. The "bordered" style features simple borders between each item, while the "contained" style sets each item within its own enclosed container. */
	itemStyle?: AccordionContextType['itemStyle'];
};

interface AccordionItemProps extends RadixAccordion.AccordionItemProps {
	/** The title to apply to this accordion item, if it should be different than the "value" prop. This can be useful if you for some reason need two accordion items with the same title, since "value" must be unique. */
	title?: string;

	/** A replacement class string to apply to this accordion item's content area. Providing your own classes will allow you to completely override any built-in styles, such as padding or text sizing. */
	contentClasses?: string;

	/** Styles the accordion item as the "dark" variant. */
	dark?: boolean;

	/** Styles the accordion item to make it more printer friendly. */
	printable?: boolean;
}

/**
 * A set of collapsible content containers.
 */
export function Accordion({ itemStyle = defaultAccordionItemStyle, children, className, ...props }: AccordionProps) {
	const validSubcomponentNames = Object.keys(Accordion);

	const subcomponents = Children.toArray(children).filter(
		(child) =>
			isValidElement(child) && typeof child.type !== 'string' && validSubcomponentNames.includes(child.type?.name)
	);

	return (
		<AccordionContext.Provider value={{ itemStyle }}>
			<RadixAccordion.Root
				className={classNames(
					itemStyle === 'bordered' && 'border-t border-t-gray-200',
					itemStyle === 'contained' && 'flex flex-col gap-2',
					className
				)}
				{...props}
			>
				{subcomponents.map((subcomponent) => subcomponent)}
			</RadixAccordion.Root>
		</AccordionContext.Provider>
	);
}

/**
 * An individual collapsible content container, which exists as part of a larger Accordion.
 */
function Item({ title, contentClasses, children, className, dark, printable, ...props }: AccordionItemProps) {
	return (
		<AccordionContext.Consumer>
			{({ itemStyle }) => (
				<RadixAccordion.Item
					className={classNames(
						itemStyle === 'bordered' &&
							!printable && [
								'border-y border-b-gray-200 -mt-px',
								'hover:border-blue-700 data-open:border-blue-700',
								'transition-[border-color]',
							],
						className
					)}
					{...props}
				>
					{props.value !== 'undefined' && (
						<RadixAccordion.Header>
							<RadixAccordion.Trigger
								className={classNames(
									`group flex items-center w-full px-6 ${!printable ? 'font-secondary' : 'text-h4'}`,
									itemStyle === 'bordered' && !printable && 'h-14',
									itemStyle === 'contained' && ['h-12', 'transition-colors'],
									itemStyle === 'bordered' && !dark && 'text-blue-700',
									itemStyle === 'contained' && !dark && 'text-blue-600 bg-blue-50 hover:bg-gray-50 data-open:bg-gray-50',
									itemStyle === 'dark' &&
										!dark &&
										'text-green-800 bg-green-100 hover:bg-gray-50 data-open:bg-green-500 data-open:text-white',
									itemStyle === 'yellow' &&
										!dark &&
										'text-yellow-800 bg-yellow-100 animate-pulse hover:bg-gray-50 data-open:bg-yellow-500 data-open:text-white',

									itemStyle === 'dark' && !printable && ['h-12', 'transition-colors'],
									itemStyle === 'yellow' && ['h-12', 'transition-colors'],
									dark && !printable && 'bg-blue-900 text-white',
									printable === true && ['pb-3 font-bold pl-32']
								)}
							>
								{title || props.value}
								{!printable && (
									<span
										className={classNames(
											'h-[20px] w-[20px] grid place-items-center text-blue-600 rounded-full ml-auto',
											'group-hover:bg-blue-600 group-hover:text-blue-50',
											'group-data-open:text-blue-50 group-data-open:-rotate-180',
											'transition-[transform,color,background-color]',
											itemStyle === 'bordered' && 'bg-blue-50 group-data-open:bg-blue-600 group-data-open:before:border-t-blue-50',
											itemStyle === 'contained' &&
												'bg-gray-100 group-data-open:bg-blue-600 group-data-open:before:border-t-blue-50',
											itemStyle === 'dark' && 'bg-gray-100 group-data-open:bg-white group-data-open:before:border-t-blue-500',
											itemStyle === 'yellow' && 'bg-gray-100 group-data-open:bg-white group-data-open:before:border-t-yellow-500',
											// `:before` pseudo-element styles
											'before:mt-px before:border-t-[5px] before:border-x-[5px] before:border-x-transparent before:border-t-blue-600',
											'group-hover:before:border-t-blue-50',
											'before:transition-colors'
										)}
									/>
								)}
							</RadixAccordion.Trigger>
						</RadixAccordion.Header>
					)}

					<RadixAccordion.Content
						className={classNames(
							itemStyle === 'contained' && 'border-2 border-t-0 border-gray-50 rounded-b-sm',
							itemStyle === 'dark' && 'border-2 border-t-0 border-green-500 rounded-b-sm',
							itemStyle === 'yellow' && 'border-2 border-t-0 border-yellow-500 rounded-b-sm',
							contentClasses || {
								'text-p2 px-9 pt-2 pb-10': itemStyle === 'bordered',
								'text-p2 px-12 py-8': itemStyle === 'contained' || itemStyle === 'dark' || itemStyle === 'yellow',
							}
						)}
					>
						{children}
					</RadixAccordion.Content>
				</RadixAccordion.Item>
			)}
		</AccordionContext.Consumer>
	);
}

Accordion.Item = Item;

export default Accordion;
