export const QUARTERS = ['1', '2', '3', '4'] as const;

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
] as const;

export type DAYS_OF_WEEK = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
export const DAYS_OF_WEEK_LIST = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
] as const;
export const BUSINESS_DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] as const;

export const PRIMETIME_VALUES = ['All', 'Primetime', 'Non-Primetime'] as const;

export const ADD_ONS_VALUES = ['All', 'Add-Ons', 'Scheduled'] as const;

export const UTILIZATION_TYPE_VALUES = ['Adjusted', 'Raw'] as const;

export const ABANDONED_DAYS_TOGGLE_VALUES = ['Included', 'Excluded'] as const;

export const TIMESLOT_TYPE_VALUES = ['Scheduled', 'Available'] as const;

export const BLOCK_TIMESLOT_VALUES = ['Full Day', 'Morning', 'Afternoon'] as const;

/**
 * This constant was only referenced in one place in v1 and should therefore
 * probably be moved to live in the equivalent component file when it eventually
 * gets reimplemented. For now, this exists to ensure its value isn't lost.
 */
export const NAVIGATOR_TICKET_TYPES = {
	'General Meeting': {
		subject:
			'Scheduling a general (not time-sensitive) meeting with your Customer Success Manager. Use this time to ask general questions about any reports or features within Merlin, discuss new or up and coming features.',
		example: 'Any thoughts, issues, or concerns that your Customer Success Manager will be happy to help you with!',
	},
	'Data Review': {
		subject:
			'An in-depth meeting to discuss Merlin metrics, brainstorm ideas for process improvements, or plan a new initiative for Surgical Services operations.',
		example:
			'You have an upcoming Surgical Governance Committee meeting, and you want a fresh pair of eyes before sharing last month’s metrics with the Committee Leadership.',
	},
	'Block Management': {
		subject:
			'For a detailed review of block utilization performance, suggested block modifications, or other in-depth block analyses',
		example:
			'There’s an upcoming Block Subcommittee meeting and you want to review last quarter’s utilization performance and document any suggested modifications before going into the meeting.',
	},
	OTHER: {
		subject: 'For any other issues',
		example: '',
	},
} as const;

/** Regular expression, allows an empty string, or a string with only valid characters (letters, numbers, and several symbols) */
export const inputFieldRegex = /^$|^(?=.*[a-zA-Z]+)[-.()[\],'/ \w, &]*$/;

export const delay_reasons = [
	{ id: 2, name: 'ANESTHESIA - Equipment / Room Setup Delay' },
	{ id: 3, name: 'ANESTHESIA - Insufficient Anesthesia Coverage' },
	{ id: 4, name: 'ANESTHESIA - CRNA unavailable' },
	{ id: 5, name: 'ANESTHESIA - Anesthesiologist unavailable' },
	{ id: 6, name: 'ANESTHESIA - Assessment not completed' },
	{ id: 7, name: 'ANESTHESIA - Consent not completed' },
	{ id: 8, name: 'ANESTHESIA - Further work up indicated' },
	{ id: 9, name: 'ANESTHESIA - Block/Spinal Delay' },
	{ id: 10, name: 'SURGEON - Surgeon late arrival to Pre-op' },
	{ id: 11, name: 'SURGEON - Patient / Surgeon pre-op consultation' },
	{ id: 12, name: 'SURGEON - Medication Order Discrepancy' },
	{ id: 13, name: 'SURGEON - Secondary surgeon unavailable (dual case)' },
	{ id: 14, name: 'SURGEON - Consent' },
	{ id: 15, name: 'SURGEON - H&P' },
	{ id: 16, name: 'SURGEON - Medical/Cardiac Clearance' },
	{ id: 17, name: 'SURGEON - Site Not Marked' },
	{ id: 18, name: 'SURGEON - Further work-up indicated' },
	{ id: 19, name: 'PREP-OP - Staff Unavailable' },
	{ id: 20, name: 'PREP-OP - Transporter Unavailable' },
	{ id: 21, name: 'PREP-OP - Patient late arrival to Pre-Op' },
	{ id: 22, name: 'PREP-OP - Language / Communication Barrier' },
	{ id: 23, name: 'PREP-OP - Pre-Op beds at full capacity' },
	{ id: 24, name: 'PREP-OP - IV Access' },
	{ id: 25, name: 'PREP-OP - Incomplete / Abnormal Labs / Diagnostic Testing' },
	{ id: 26, name: 'PREP-OP - Pre-Op nursing assessment incomplete' },
	{ id: 27, name: 'PREP-OP - Pre-Op Checklist incomplete' },
	{ id: 28, name: 'PREP-OP - Surgical Site Preparation Incomplete' },
	{ id: 29, name: 'PREP-OP - Additional pre-op orders received' },
	{ id: 30, name: 'PREP-OP - Patient to Bathroom' },
	{ id: 31, name: 'PREP-OP - NPO Status Discrepancy' },
	{ id: 32, name: 'OPERATING ROOM - Change in Order of Cases' },
	{ id: 33, name: 'OPERATING ROOM - OR Staff Unavailable / Delay' },
	{ id: 34, name: 'OPERATING ROOM - Scheduling Discrepancy' },
	{ id: 35, name: 'OPERATING ROOM - Preference Card Discrepancy' },
	{ id: 36, name: 'OPERATING ROOM - Equipment Malfunction / Unavailable' },
	{ id: 37, name: 'OPERATING ROOM - Industry Representative Unavailable' },
	{ id: 38, name: 'OPERATING ROOM - Implants / Loaned Items Unavailable' },
	{ id: 39, name: 'OPERATING ROOM - Contamination Delay' },
	{ id: 40, name: 'OPERATING ROOM - Instrumentation Delay' },
	{ id: 41, name: 'OPERATING ROOM - Prior case Over Run' },
	{ id: 42, name: 'OPERATING ROOM - Delay in Room Cleaning' },
	{ id: 43, name: 'OPERATING ROOM - Emergency Add-On Case' },
	{ id: 44, name: 'PACU - PACU beds at full Capacity' },
	{ id: 45, name: 'PACU - PACU staff unavailable' },
	{ id: 46, name: 'PACU - Post-Op Bed Unavailable' },
	{ id: 47, name: 'MISCELLANEOUS - Radiology Tech Unavailable / Delay' },
	{ id: 48, name: 'MISCELLANEOUS - Radiology Equipment Unavailable' },
	{ id: 49, name: 'MISCELLANEOUS - Ultrasound Unavailable / Delay' },
	{ id: 50, name: 'MISCELLANEOUS - Respiratory Unavailable / Delay' },
	{ id: 51, name: 'MISCELLANEOUS - Pharmacy / Medication Delay' },
	{ id: 52, name: 'MISCELLANEOUS - Delay in Hand Off Report' },
	{ id: 53, name: 'MISCELLANEOUS - Support Person Not Present' },
	{ id: 54, name: 'MISCELLANEOUS - Insurance Issues' },
	{ id: 55, name: 'MISCELLANEOUS - No Delay' },
];
